import { computed } from 'vue';
import store from '@/store';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useCustomFields from '@/composables/app/useCustomFields';
import { singular, plural } from 'pluralize';

const useSettings = () => {
    const { deepCompare, stringCapitalize, textCapitalize } = useCommonUtils();

    const { customFields: customFieldTables } = useCustomFields();

    const getCustomFields = (tableName, startIndex) => {
        const table = customFieldTables.value.find(
            (table) => table.table === tableName && table?.active
        );
        if (!table) return {};

        const fields = (table?.fields || []).filter(
            (field) => field?.active && field?.show_on_table
        );
        if (!fields?.length) return {};

        return fields.reduce((r, field, index) => {
            const slug = field?.title?.toLowerCase()?.replaceAll(' ', '_');
            const duplicate = fields.some(
                (df) =>
                    df?.id !== field?.id &&
                    df?.title?.toLowerCase()?.replaceAll(' ', '_') === slug
            );

            if (duplicate)
                return {
                    ...r,
                    [field.key]: {
                        key: field?.key,
                        name: `${slug}(${field.key.replaceAll('_', '-')})`,
                        index: startIndex + index,
                        show: true,
                        custom: true,
                    },
                };

            return {
                ...r,
                [field.key]: {
                    key: field?.key,
                    name: slug,
                    index: startIndex + index,
                    show: true,
                    custom: true,
                },
            };
        }, {});
    };

    const defaultCommonSettings = computed(() => {
        return {
            tables: {
                organizations: {
                    columns: {
                        name: {
                            index: 0,
                            name: 'name',
                            key: 'name',
                            show: true,
                        },
                        industry: {
                            key: 'industry',
                            index: 1,
                            name: 'industry',
                            show: true,
                        },
                        address: {
                            key: 'address',
                            index: 2,
                            name: 'address',
                            show: true,
                        },
                        owner: {
                            key: 'owner',
                            index: 3,
                            name: 'owner',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 4,
                            name: 'status',
                            show: true,
                        },
                        created_on: {
                            key: 'created_on',
                            index: 5,
                            name: 'created_on',
                            show: true,
                        },
                        updated_on: {
                            key: 'updated_on',
                            index: 6,
                            name: 'updated_on',
                            show: true,
                        },
                        ...getCustomFields('organizations', 7),
                    },
                },

                companies: {
                    columns: {
                        name: {
                            index: 0,
                            name: 'name',
                            key: 'name',
                            show: true,
                        },
                        type: {
                            key: 'type',
                            index: 1,
                            name: 'type',
                            show: true,
                        },
                        organization: {
                            key: 'organization',
                            index: 2,
                            name: 'organization',
                            show: true,
                        },
                        address: {
                            key: 'address',
                            index: 3,
                            name: 'address',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 4,
                            name: 'status',
                            show: true,
                        },
                        created_on: {
                            key: 'created_on',
                            index: 5,
                            name: 'created_on',
                            show: true,
                        },
                        updated_on: {
                            key: 'updated_on',
                            index: 6,
                            name: 'updated_on',
                            show: true,
                        },
                        ...getCustomFields('companies', 7),
                    },
                },

                business_units: {
                    columns: {
                        name: {
                            index: 0,
                            name: 'name',
                            key: 'name',
                            show: true,
                        },
                        type: {
                            key: 'type',
                            index: 1,
                            name: 'type',
                            show: true,
                        },
                        organization: {
                            key: 'organization',
                            index: 2,
                            name: 'organization',
                            show: true,
                        },
                        company: {
                            key: 'company',
                            index: 3,
                            name: 'company',
                            show: true,
                        },
                        address: {
                            key: 'address',
                            index: 4,
                            name: 'address',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 5,
                            name: 'status',
                            show: true,
                        },
                        created_on: {
                            key: 'created_on',
                            index: 6,
                            name: 'created_on',
                            show: true,
                        },
                        updated_on: {
                            key: 'updated_on',
                            index: 7,
                            name: 'updated_on',
                            show: true,
                        },
                        ...getCustomFields('business_units', 8),
                    },
                },

                departments: {
                    columns: {
                        name: {
                            index: 0,
                            name: 'name',
                            key: 'name',
                            show: true,
                        },
                        organization: {
                            key: 'organization',
                            index: 1,
                            name: 'organization',
                            show: true,
                        },
                        company: {
                            key: 'company',
                            index: 2,
                            name: 'company',
                            show: true,
                        },
                        business_unit: {
                            key: 'business_unit',
                            index: 3,
                            name: 'business_unit',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 4,
                            name: 'status',
                            show: true,
                        },
                        created_on: {
                            key: 'created_on',
                            index: 5,
                            name: 'created_on',
                            show: true,
                        },
                        updated_on: {
                            key: 'updated_on',
                            index: 6,
                            name: 'updated_on',
                            show: true,
                        },
                        ...getCustomFields('departments', 7),
                    },
                },

                jobs: {
                    columns: {
                        job_title: {
                            index: 0,
                            name: 'job_title',
                            key: 'job_title',
                            show: true,
                        },
                        organization: {
                            key: 'organization',
                            index: 1,
                            name: 'organization',
                            show: true,
                        },
                        company: {
                            key: 'company',
                            index: 2,
                            name: 'company',
                            show: true,
                        },
                        business_unit: {
                            key: 'business_unit',
                            index: 3,
                            name: 'business_unit',
                            show: true,
                        },
                        department: {
                            key: 'department',
                            index: 4,
                            name: 'department',
                            show: true,
                        },
                        total_candidates: {
                            key: 'total_candidates',
                            index: 5,
                            name: 'total_candidates',
                            show: true,
                        },
                        openings: {
                            key: 'openings',
                            index: 6,
                            name: 'openings',
                            show: true,
                        },
                        job_status: {
                            key: 'job_status',
                            index: 7,
                            name: 'job_status',
                            show: true,
                        },

                        open_date: {
                            key: 'open_date',
                            index: 8,
                            name: 'open_date',
                            show: true,
                        },
                        close_date: {
                            key: 'close_date',
                            index: 9,
                            name: 'close_date',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 10,
                            name: 'status',
                            show: true,
                        },
                        created_on: {
                            key: 'created_on',
                            index: 11,
                            name: 'created_on',
                            show: true,
                        },
                        updated_on: {
                            key: 'updated_on',
                            index: 12,
                            name: 'updated_on',
                            show: true,
                        },
                        ...getCustomFields('jobs', 13),
                    },
                },

                candidates: {
                    columns: {
                        id: { index: 0, name: 'id', key: 'id', show: true },
                        name: {
                            index: 1,
                            name: 'name',
                            key: 'name',
                            show: true,
                        },
                        job: {
                            key: 'job',
                            index: 2,
                            name: 'job',
                            show: true,
                        },
                        age: {
                            key: 'age',
                            index: 3,
                            name: 'age',
                            show: true,
                        },
                        current_company: {
                            key: 'current_company',
                            index: 4,
                            name: 'current_company',
                            show: true,
                        },
                        current_salary: {
                            key: 'current_salary',
                            index: 5,
                            name: 'current_salary',
                            show: true,
                        },
                        expected_salary: {
                            key: 'expected_salary',
                            index: 6,
                            name: 'expected_salary',
                            show: true,
                        },

                        status: {
                            key: 'status',
                            index: 7,
                            name: 'status',
                            show: true,
                        },
                        candidate_phone: {
                            key: 'candidate_phone',
                            index: 8,
                            name: 'candidate_phone',
                            show: true,
                        },
                        latest_education: {
                            key: 'latest_education',
                            index: 9,
                            name: 'latest_education',
                            show: true,
                        },
                        total_experiences: {
                            key: 'total_experiences',
                            index: 10,
                            name: 'total_experiences',
                            show: true,
                        },
                        created_on: {
                            key: 'created_on',
                            index: 11,
                            name: 'created_on',
                            show: true,
                        },
                        updated_on: {
                            key: 'updated_on',
                            index: 12,
                            name: 'updated_on',
                            show: true,
                        },
                        cv_source: {
                            key: 'cv_source',
                            index: 13,
                            name: 'cv_source',
                            show: true,
                        },
                        created_by: {
                            key: 'created_by',
                            index: 14,
                            name: 'created_by',
                            show: true,
                        },
                        updated_by: {
                            key: 'updated_by',
                            index: 15,
                            name: 'updated_by',
                            show: true,
                        },
                        ...getCustomFields('candidates', 16),
                        current_position: {
                            key: 'current_position',
                            index: 17,
                            name: 'current_position',
                            show: true,
                        },
                    },
                },

                applicants: {
                    columns: {
                        applicant: {
                            index: 0,
                            name: 'applicant',
                            key: 'applicant',
                            show: true,
                        },
                        organization: {
                            key: 'organization',
                            index: 1,
                            name: 'organization',
                            show: true,
                        },
                        contact_person: {
                            key: 'contact_person',
                            index: 2,
                            name: 'contact_person',
                            show: true,
                        },

                        age: {
                            key: 'age',
                            index: 3,
                            name: 'age',
                            show: true,
                        },
                        job: {
                            key: 'job',
                            index: 4,
                            name: 'job',
                            show: true,
                        },
                        job_status: {
                            key: 'job_status',
                            index: 5,
                            name: 'job_status',
                            show: true,
                        },

                        workflow: {
                            key: 'workflow',
                            index: 6,
                            name: 'workflow',
                            show: true,
                        },
                        stage: {
                            key: 'stage',
                            index: 7,
                            name: 'stage',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 8,
                            name: 'status',
                            show: true,
                        },
                        candidate_phone: {
                            key: 'candidate_phone',
                            index: 9,
                            name: 'candidate_phone',
                            show: true,
                        },
                        latest_education: {
                            key: 'latest_education',
                            index: 10,
                            name: 'latest_education',
                            show: true,
                        },
                        total_experiences: {
                            key: 'total_experiences',
                            index: 11,
                            name: 'total_experiences',
                            show: true,
                        },
                        current_salary: {
                            key: 'current_salary',
                            index: 12,
                            name: 'current_salary',
                            show: true,
                        },
                        expected_salary: {
                            key: 'expected_salary',
                            index: 13,
                            name: 'expected_salary',
                            show: true,
                        },
                        rating: {
                            key: 'rating',
                            index: 14,
                            name: 'rating',
                            show: true,
                        },
                        ...getCustomFields('applicants', 15),
                        current_company: {
                            key: 'current_company',
                            index: 16,
                            name: 'current_company',
                            show: true,
                        },
                        current_position: {
                            key: 'current_position',
                            index: 17,
                            name: 'current_position',
                            show: true,
                        },
                        updated_at: {
                            key: 'updated_at',
                            index: 18,
                            name: 'updated_at',
                            show: true,
                        },
                        cv_source: {
                            key: 'cv_source',
                            index: 19,
                            name: 'cv_source',
                            show: true,
                        },
                        business_unit: {
                            key: 'business_unit',
                            index: 20,
                            name: 'business_unit',
                            show: true,
                        },
                        department: {
                            key: 'department',
                            index: 21,
                            name: 'department',
                            show: true,
                        },
                        company: {
                            key: 'company',
                            index: 22,
                            name: 'company',
                            show: true,
                        },
                        job_creator: {
                            key: 'job_creator',
                            index: 23,
                            name: 'job_creator',
                            show: true,
                        },
                        cv_created_by: {
                            key: 'cv_created_by',
                            index: 24,
                            name: 'cv_created_by',
                            show: true,
                        },
                        cv_created_date: {
                            key: 'cv_created_date',
                            index: 25,
                            name: 'cv_created_date',
                            show: true,
                        },
                        moved_to_onboarding: {
                            key: 'moved_to_onboarding',
                            index: 26,
                            name: 'moved_to_onboarding',
                            show: true,
                        },
                    },
                },

                interviews: {
                    columns: {
                        name: {
                            index: 0,
                            name: 'name',
                            key: 'name',
                            show: true,
                        },
                        type: {
                            index: 1,
                            key: 'type',
                            name: 'type',
                            show: true,
                        },
                        date: {
                            key: 'date',
                            index: 2,
                            name: 'date',
                            show: true,
                        },
                        interviewers: {
                            key: 'interviewers',
                            index: 3,
                            name: 'interviewers',
                            show: true,
                        },
                        feedback: {
                            key: 'feedback',
                            index: 4,
                            name: 'feedback',
                            show: true,
                        },

                        link: {
                            key: 'link',
                            index: 5,
                            name: 'link',
                            show: true,
                        },
                        location: {
                            key: 'location',
                            index: 6,
                            name: 'location',
                            show: true,
                        },
                        status: {
                            key: 'status',
                            index: 7,
                            name: 'status',
                            show: true,
                        },
                        action: {
                            key: 'action',
                            index: 8,
                            name: 'action',
                            show: true,
                        },
                        ...getCustomFields('interviews', 9),
                    },
                },
            },
            sidebarMenuNames: {
                dashboard: {
                    default_name: 'Dashboard',
                    name: 'Dashboard',
                },
                recruitments: {
                    default_name: 'Recruitments',
                    name: 'Recruitments',
                },
                organizations: {
                    default_name: 'Organizations',
                    name: 'Organizations',
                },
                companies: {
                    default_name: 'Companies',
                    name: 'Companies',
                },
                business_units: {
                    default_name: 'Business units',
                    name: 'Business units',
                },
                departments: {
                    default_name: 'Departments',
                    name: 'Departments',
                },
                jobs: {
                    default_name: 'Jobs',
                    name: 'Jobs',
                },
                candidates: {
                    default_name: 'Candidates',
                    name: 'Candidates',
                },
                applicants: {
                    default_name: 'Applicants',
                    name: 'Applicants',
                },
                onboarding: {
                    default_name: 'Onboarding',
                    name: 'Onboarding',
                },
                resume_search: {
                    default_name: 'Resume search',
                    name: 'Resume search',
                },
                calendar: {
                    default_name: 'Calendar',
                    name: 'Calendar',
                },
                email: {
                    default_name: 'Email',
                    name: 'Email',
                },
                master_settings: {
                    default_name: 'Master settings',
                    name: 'Master settings',
                },
                account_and_users: {
                    default_name: 'Account & Users',
                    name: 'Account & Users',
                },
                master_view: {
                    default_name: 'Master view',
                    name: 'Master view',
                },
                career_page: {
                    default_name: 'Career page',
                    name: 'Career page',
                },
                job_board: {
                    default_name: 'Job board',
                    name: 'Job board',
                },
                approval_matrix: {
                    default_name: 'Approval matrix',
                    name: 'Approval matrix',
                },
                integrations: {
                    default_name: 'Integrations',
                    name: 'integrations',
                },
                visa_quota: {
                    default_name: 'Visa quota',
                    name: 'Visa quota',
                },
                feedback: {
                    default_name: 'Feedback',
                    name: 'Feedback',
                },
                reports: {
                    default_name: 'Reports',
                    name: 'Reports',
                },
            },
            offerManager: {
                show_yearly_salary_breakup: true,
                show_monthly_salary_breakup: true,
            },
        };
    });

    // default user settings defined in this file
    const defaultUserSettings = computed(() => {
        return {
            ...defaultCommonSettings.value,
        };
    });

    // default team settings defined in this file
    const defaultTeamSettings = computed(() => {
        return {
            ...defaultCommonSettings.value,
            masterSettings: {
                hierarchyBasedDesignation: false,
            },
        };
    });

    const isUserSettingsLoaded = computed(() =>
        Boolean(store?.state?.settings?.userSettings?.id)
    );
    // user settings saved on database
    const savedUserSettings = computed(() =>
        store?.state?.settings?.userSettings?.settings
            ? store?.state?.settings?.userSettings?.settings
            : {}
    );

    // compared user settings between saved and default.
    // this is the actual user settings
    const userSettings = computed({
        get: () => {
            const savedSettings = store?.state?.settings?.userSettings?.settings
                ? store?.state?.settings?.userSettings?.settings
                : {};

            return deepCompare(savedSettings, defaultUserSettings.value);
        },
        set: (settings) => {
            store.commit('settings/updateUserSettings', settings);
        },
    });

    const isTeamSettingsLoaded = computed(() =>
        Boolean(store?.state?.settings?.teamSettings?.id)
    );
    // team settings saved on database
    const savedTeamSettings = computed(() =>
        store?.state?.settings?.teamSettings?.settings
            ? store?.state?.settings?.teamSettings?.settings
            : {}
    );

    // compared team settings between saved and default.
    // this is the actual team settings
    const teamSettings = computed({
        get: () => {
            const savedSettings = store?.state?.settings?.teamSettings?.settings
                ? store?.state?.settings?.teamSettings?.settings
                : {};
            return deepCompare(savedSettings, defaultTeamSettings.value);
        },
        set: (settings) => {
            store.commit('settings/updateTeamSettings', settings);
        },
    });

    // merged settings saved on databse
    const savedSettings = computed(() =>
        deepCompare(savedUserSettings.value, savedTeamSettings.value)
    );

    // actual merged settings
    const settings = computed(() =>
        deepCompare(userSettings.value, teamSettings.value)
    );

    const organizationTableColumns = computed(() =>
        getTableColumns('organizations')
    );

    const companyTableColumns = computed(() => getTableColumns('companies'));

    const businessUnitTableColumns = computed(() =>
        getTableColumns('business_units')
    );

    const departmentTableColumns = computed(() =>
        getTableColumns('departments')
    );

    const jobTableColumns = computed(() => getTableColumns('jobs'));

    const candidateTableColumns = computed(() => getTableColumns('candidates'));

    const applicantTableColumns = computed(() => getTableColumns('applicants'));

    const interviewTableColumns = computed(() => getTableColumns('interviews'));

    const getTableColumns = (tableName) => {
        // as by structure, default table settings are same for both user default and team default, for default
        // we can take any one of defautUserSettings or defaultTeamSettings.
        // in this case, we have took defaultUserSettings
        const defaultColumns =
            defaultUserSettings.value?.tables[tableName]?.columns || {};

        const settingsColumns = deepCompare(
            savedSettings.value?.tables &&
                savedSettings.value?.tables[tableName] &&
                savedSettings.value?.tables[tableName]?.columns
                ? savedSettings.value?.tables[tableName]?.columns
                : {},
            defaultColumns
        );

        const defaultColumnValueKeys = Object.values(defaultColumns).map(
            (col) => col?.key
        );
        const settingsColumnValueKeys = Object.values(settingsColumns).map(
            (col) => col?.key
        );

        // default columns should have updated instance of custom_fields
        // filtering saved columns whether they exists on default columns i.e; updated instance of custom_fields
        // if column not exists on default it is been discarded
        const outputColumns = Object.values(settingsColumns)
            .sort((a, b) => a?.index - b?.index)
            .reduce((r, column) => {
                if (defaultColumnValueKeys.includes(column?.key))
                    return { ...r, [column?.key]: column };
                return r;
            }, {});

        // now checking which default columns not exists on the saved
        // we will append these columns
        const columnsNotSaved = Object.values(defaultColumns).reduce(
            (r, column) => {
                if (!settingsColumnValueKeys.includes(column?.key))
                    return { ...r, [column?.key]: column };
                return r;
            },
            {}
        );

        return { ...outputColumns, ...columnsNotSaved };
    };

    const initialDataLoading = computed(
        () => !Boolean(store.state?.storeDataLoaded)
    );

    const resolveSidebarMenuName = (key, options = {}) => {
        const menuNames = teamSettings.value?.sidebarMenuNames || {};
        if (!menuNames || !menuNames[key]) return key;

        let name = menuNames[key]?.name;

        if (options?.singular) name = singular(name);
        else if (options?.plural) name = plural(name);

        if (options?.lowerCase) name = name?.toLowerCase();
        else if (options?.upperCase) name = name?.toUpperCase();
        else if (options?.capitalFirst) name = stringCapitalize(name);
        else if (options?.capitalAll) name = textCapitalize(name);

        return name;
    };

    const organizationAlias = computed(() => {
        return {
            singular: resolveSidebarMenuName('organizations', {
                singular: true,
            }),
            plural: resolveSidebarMenuName('organizations', { plural: true }),
        };
    });

    const companyAlias = computed(() => {
        return {
            singular: resolveSidebarMenuName('companies', { singular: true }),
            plural: resolveSidebarMenuName('companies', { plural: true }),
        };
    });

    const businessUnitAlias = computed(() => {
        return {
            singular: resolveSidebarMenuName('business_units', {
                singular: true,
            }),
            plural: resolveSidebarMenuName('business_units', { plural: true }),
        };
    });

    const departmentAlias = computed(() => {
        return {
            singular: resolveSidebarMenuName('departments', { singular: true }),
            plural: resolveSidebarMenuName('departments', { plural: true }),
        };
    });

    return {
        userSettings,
        isUserSettingsLoaded,
        teamSettings,
        isTeamSettingsLoaded,
        settings,
        organizationTableColumns,
        initialDataLoading,
        companyTableColumns,
        businessUnitTableColumns,
        departmentTableColumns,
        jobTableColumns,
        candidateTableColumns,
        applicantTableColumns,
        interviewTableColumns,
        resolveSidebarMenuName,
        organizationAlias,
        companyAlias,
        businessUnitAlias,
        departmentAlias,
    };
};

export default useSettings;
