import { format, subDays, isValid } from 'date-fns';
import {
  utcToZonedTime,
  format as tzFormat,
  zonedTimeToUtc
} from 'date-fns-tz';
const useDate = () => {
  const tableDateFilterOptions = [
    {
      id: format(subDays(new Date(), 7), 'dd-MM-yyyy'),
      text: 'Last 7 days'
    },
    {
      id: format(subDays(new Date(), 15), 'dd-MM-yyyy'),
      text: 'Last 15 days'
    },
    {
      id: format(subDays(new Date(), 30), 'dd-MM-yyyy'),
      text: 'Last month'
    },
    {
      id: format(subDays(new Date(), 90), 'dd-MM-yyyy'),
      text: 'Last 3 months'
    },
    {
      id: format(subDays(new Date(), 365), 'dd-MM-yyyy'),
      text: 'Last year'
    }
  ];

  const formatDate = (date, formatString = 'dd-MM-yyyy') => {
    if (!date) return '';
    date = new Date(date);
    if (!isValid(date)) return '';
    return format(date, formatString);
  };

  const getAvailableTimeZones = () => {
    const timeZones = Intl.supportedValuesOf('timeZone');
    return timeZones.map((timeZone) => {
      // Convert UTC time to the specified time zone
      const zonedTime = utcToZonedTime(new Date(), timeZone);

      // Format the time to extract the offset
      const offset = tzFormat(zonedTime, 'xxx', { timeZone });
      return { timezone: timeZone, offset };
    });
  };

  const convertToZonedTime = (date, timeZone) => {
    if (!(date instanceof Date)) date = new Date(date);

    const utcDate = zonedTimeToUtc(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const zonedDate = utcToZonedTime(utcDate, timeZone);

    return new Date(date.getTime() - (utcDate.getTime() - zonedDate.getTime()));
  };

  return {
    tableDateFilterOptions,
    formatDate,
    getAvailableTimeZones,
    convertToZonedTime
  };
};

export default useDate;
